<template>
  <div class="w-100 position-relative" style="overflow-y: auto">
    <CCard class="pdf-wrapper">
      <div class="modal-body modal-container">
        <div class="pdf-wrapper">
          <div class="pdf-page fs-16">
            <!--            <button class="c-sidebar-close c-class-toggler close">-->
            <!--              <i class="c-icon cil-x"></i> Quay lại-->
            <!--            </button>-->
            <div id="pdf1" class="pdf-content ml-3 mr-3">
              <div class="text-center fs-16">
                <strong class="bold">Mẫu C.III.1</strong><br>
                <strong>Văn bản về phương án xúc tiến đầu tư trong hoạt động</strong><br>
                <strong>đối ngoại cấp cao nhà nước</strong><br>
                <p class="font-italic">(Khoản 1, Điều 94 Nghị định số 31/2021/NĐ-CP)</p>
                <hr>
              </div>
              <div class="row fs-16">
                <div class="col-md-4">
                  <CInput placeholder="TÊN CƠ QUAN CHỦ TRÌ" horizontal :value.sync="item.tenCoQuanChuTri"/>
                  <CInput placeholder="Số:" horizontal :value.sync="item.soVanBan"/>
                </div>
                <div class="col-md-4"/>
                <div class="col-md-4">
                  <p class="text-center text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt Nam</p>
                  <p class="text-center font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>
                  <p class="font-weight font-italic" style="float: right">
                    <CInput placeholder="Địa điểm:" horizontal :value.sync="item.diaDiem"/>
                    <CInput type="date" placeholder="Ngày văn bản:" horizontal :value.sync="item.ngayVanBan"/>
                    {{item.diaDiem}}, ngày ... tháng ... năm ...</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4" style="float: left">
                  <p class="font-weight">
                    V/v phương án xúc tiến đầu tư trong hoạt động đối ngoại cấp cao nhà nước
                  </p>
                </div>
              </div>
              <div class="text-center">
                <p class="mb-0 text-indent">Thực hiện chỉ đạo của ... tại công văn số ... ngày ..., (Tên cơ quan chủ trì) được giao tổ chức
                  thực hiện hoạt động đối ngoại cấp cao nhà nước trong đó có hoạt động xúc tiến đầu tư.
                </p>
                <p class="text-indent">
                  Căn cứ quy định tại Khoản 1 Điều 94 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021 quy định chi tiết và hướng
                  dẫn thi hành một số điều của Luật Đầu tư, (tên cơ quan chủ trì) đề nghị Bộ Kế hoạch và Đầu tư có ý kiến về
                  Phương Án xúc tiến đầu tư trong hoạt động đối ngoại cấp cao nhà nước, cụ thể như sau:
                </p>
              </div>
              <div class="row">
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>1. Tên hoạt động xúc tiến đầu tư:</label>
                  </div>
                  <div class="col-8">
                    <CInput placeholder="Nhập tên hoạt động đầu tư" horizontal :value.sync="item.tenHoatDong"/>
                  </div>
                </div>
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>2. Thời gian thực hiện:</label>
                  </div>
                  <div class="col-4">
                    <v-date-picker class="" v-model="item.thoiGianThucHien" locale="vi">
                      <template v-slot="{ inputValue, inputEvents }">
                        <CInput
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="Chọn ngày"
                        />
                      </template>
                    </v-date-picker>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">3. Địa điểm (trong nước/nước ngoài):</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Trong nước:</li>
                        <li class="mt-4">Nước ngoài:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <!--                      <select  class="form-group" v-model="item.diaDiem_TrongNuoc">-->
                      <!--                        <option v-for="it in optionsTinhThanh" :value="it.value" v-bind:key="it.value">{{it.label}}</option>-->
                      <!--                      </select>-->
                      <CSelect class="form-group" placeholder="Chọn tỉnh thành" :options="optionsTinhThanh" horizontal :value.sync="item.diaDiem_TrongNuoc"/>
                      <CSelect class="form-group" placeholder="Chọn quốc gia" :options="optionsQuocGia" horizontal :value.sync="item.diaDiem_NuocNgoai"/>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>4. Thành phần dự kiến tham gia:</label>
                  </div>
                  <div class="col-8">
                    <CInput class="form-group" placeholder="Nhập các thành phần dự kiến tham gia" horizontal
                            :value.sync="item.thanhPhanDuKienThamGia"/>
                  </div>
                </div>
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>5. Quy mô hoạt động:</label>
                  </div>
                  <div class="col-8">
                    <CInput class="form-group" placeholder="Nhập quy mô hoạt động" horizontal :value.sync="item.quyMoHoatDong"/>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">6. Lĩnh vực/Địa bàn kêu gọi đầu tư:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Ngành/Lĩnh vực kêu gọi đầu tư:</li>
                        <li class="mt-4">Địa bàn kêu gọi đầu tư:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <!--                      <select :value.sync="item.nganhLinhVucKeuGoiDauTuId" class="form-group">-->
                      <!--                        <option v-for="it in optionsNganhLinhVuc" :value="it.value" v-bind:key="it.value">{{it.label}}</option>-->
                      <!--                      </select>-->
                      <CSelect class="form-group" placeholder="Chọn ngành/lĩnh vực" horizontal :options="optionsNganhLinhVuc"
                               :value.sync="item.nganhLinhVucKeuGoiDauTuId"/>
                      <CSelect class="form-group" placeholder="Chọn địa bàn" horizontal :options="optionsTinhThanh" :value.sync="item.diaBanKeuGoiDauTu"/>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 ml-3">
                  <label>7. Các hoạt động chính:
                    <span class="font-italic">(bao gồm danh mục giấy chứng nhận đăng ký đầu tư/chấp thuận chủ trương đầu
                      tư thỏa thuận dự kiến trao/ký kết</span>
                  </label>
                  <div class="col-12">
                    <CInput class="form-group" placeholder="Nhập các hoạt động chính" horizontal :value.sync="item.cacHoatDongChinh"/>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">8. Cơ quan, tổ chức phối hợp thực hiện:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Tên tổ chức/cơ quan trong nước:</li>
                        <li class="mt-4">Tên tổ chức/cơ quan nước ngoài:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <CInput class="form-group" placeholder="Chọn tên tổ chức/cơ quan trong nước" horizontal
                              :value.sync="item.toChucCoQuan_TrongNuoc"/>
                      <CInput class="form-group" placeholder="Chọn tên tổ chức/cơ quan nước ngoài" horizontal
                              :value.sync="item.toChucCoQuan_NuocNgoai"/>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 ml-3">
                  <label>9. Chương trình dự kiến:</label>
                  <div class="col-12">
                    <CInput class="form-group" placeholder="Nhập chương trình dự kiến" horizontal :value.sync="item.chuongTrinhDuKien"/>
                  </div>
                </div>
                <div class="col-xl-12 ml-3">
                  <label>10. Phương án tổ chức thực hiện:</label>
                  <div class="col-12">
                    <CInput class="form-group" placeholder="Nhập phương án tổ chức thực hiện" horizontal
                            :value.sync="item.phuongAnToChucThucHien"/>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">11. Kinh phí:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Ngân sách:</li>
                        <li class="mt-4">Nguồn xã hội hóa:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <CInput class="form-group" placeholder="Nhập kinh phí nguồn ngân sách" horizontal :value.sync="item.kinhPhi_NganSach"/>
                      <CInput class="form-group" placeholder="Nhập kinh phí nguồn xã hội hóa" horizontal :value.sync="item.kinhPhi_XaHoiHoa"/>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">12. Thông tin liên hệ:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-3">
                      <ul>
                        <li>Họ tên:</li>
                        <li class="mt-4">Email:</li>
                      </ul>
                    </div>
                    <div class="col-3">
                      <CInput class="form-group" placeholder="Nhập họ tên" horizontal :value.sync="item.lienHe_HoTen"/>
                      <CInput class="form-group" placeholder="Nhập email" horizontal :value.sync="item.lienHe_Email"/>
                    </div>
                    <div class="col-3">
                      <ul>
                        <li class="">Số điện thoại:</li>
                      </ul>
                    </div>
                    <div class="col-3">
                      <CInput class="form-group" placeholder="Nhập số điện thoại" horizontal :value.sync="item.lienHe_SoDienThoai"/>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12">
                  <p class="ml-3">Đề nghị Bộ Kế hoạch và Đầu tư xem xét, thống nhất để {{item.tenCoQuanChuTri}} có cơ sở triển khai tổ chức thực hiện... /.</p>
                </div>
                <div class="col-xl-12 row">
                  <div class="col">
                    <div class="float-left">
                      <span class="font-italic font-weight-bold">Nơi nhận:</span> <br>
                      <span class="font-weight">- Như trên;</span> <br>
                      <span class="font-weight">- Lưu: VT, ...</span> <br>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12">
                  <div class="float-right mr-5">
                    <span class="font-weight-bold text-uppercase">Thủ trưởng cơ quan chủ trì</span> <br>
                    <span class="font-weight font-italic">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span>
                    <br>
                    <br>
                    <br>
                    <br>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label>File đính kèm:</label>
                  <div class="import-files mb-2" v-if="Attachments.length">
                    <div class="files mb-1" v-for="file in Attachments" v-bind:key="file.id">
                      <a :href="getDownloadURL(file.url)" target="_blank">{{ file.fileName }}</a>
                    </div>
                  </div>
                </div>
<!--                <div class="col-xl-12" v-if="yKienPheDuyetGanNhat">-->
<!--                  <label>Ý kiến phê duyệt gần nhất: <span class="font-weight-bold">{{ yKienPheDuyetGanNhat.noiDung}}</span></label>-->
<!--                  <div class="import-files mb-2">-->
<!--                    <div class="files mb-1" v-for="file in JSON.parse(yKienPheDuyetGanNhat.dinhKem)" v-bind:key="file.id">-->
<!--                      <a :href="getDownloadURL(file.url)" target="_blank">{{ file.fileName }}</a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
        <CCardFooter tag="div" class="text-right">
          <CButton color="secondary" class="mr-2" @click="back">Quay lại</CButton>
          <CButton color="ghost" class="mr-2" @click="print">Xuất văn bản</CButton>
          <CButton color="danger" @click="isDeleteConfirmationModalOpen = true">Xóa</CButton>
        </CCardFooter>
      </div>
    </CCard>
    <DeleteConfirmationModal
      title="Xóa thông báo kế hoạch tổ chức hoạt động xúc tiến hỗn hợp đầu tư, thương mại, du lịch, ngoại giao kinh tế"
      :show.sync="isDeleteConfirmationModalOpen"
      :is-deleting="isDeleting"
      :item="item" @confirm-destroy="confirmDestroy"/>
  </div>
</template>

<script>
import { statuses, loaiDonVis } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import {
  DELETE_PHUONGAN,
  GET_PHUONGAN
} from '@/store/modules/PhuongAnXTDTTrongHDDNCapCao/actionTypes'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import { GET_YKIENPHEDUYET, GET_YKIENPHEDUYETGANNHAT } from '@/store/modules/VanBanTBKHTCHDXTHHDTTMDLNGKT/actionTypes'
import appConfig from '@/shared/appConfig'

export default {
  name: 'ThongBaoKeHoachDetail',
  data () {
    return {
      Attachments: [],
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      isDeleteConfirmationModalOpen: false,
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsNganhLinhVuc: []
    }
  },
  computed: {
    ...mapGetters('phuongAnModule', {
      item: 'detailItem',
      isFetchingSingle: 'isFetchingSingle',
      isDeleting: 'isDeleting',
      yKienPheDuyetGanNhat: 'yKienPheDuyetGanNhat',
      yKienPheDuyet: 'yKienPheDuyet',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('phuongAnModule', {
      getItem: GET_PHUONGAN,
      deleteItem: DELETE_PHUONGAN,
      getYKienPheDuyet: GET_YKIENPHEDUYET,
      getYKienPheDuyetGanNhat: GET_YKIENPHEDUYETGANNHAT
    }),
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
    },
    back () {
      this.$router.push({ path: '/phuong-an-xtdt-trong-hoat-dong-doi-ngoai-cap-cao' })
    },
    print () {
      localStorage.setItem('phuong_an_xuc_tien', JSON.stringify(this.item))
      // this.$router.push({ path: `/thong-bao-ke-hoach-xt-hon-hop/${this.item.id}/bieu-in` })
      const routeData = this.$router.resolve({ path: '/phuong-an-xtdt-trong-hoat-dong-doi-ngoai-cap-cao/bieu-in' })
      window.open(routeData.href, '_blank')
    },
    editItem () {
      this.$router.push({ path: `/phuong-an-xtdt-trong-hoat-dong-doi-ngoai-cap-cao/${this.item.id}/cap-nhat` })
    },
    async confirmDestroy () {
      await this.deleteItem(this.item)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.$router.push({ path: '/phuong-an-xtdt-trong-hoat-dong-doi-ngoai-cap-cao' })
      }
    }
  },
  async created () {
    await this.getItem(this.$route.params.id)
    if (this.item.trangThai === 3) await this.getYKienPheDuyetGanNhat(this.$route.params.id)
    if (this.item.dinhKem) this.Attachments = JSON.parse(this.item.dinhKem)
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
  }
}
</script>

<style scoped>
.text-indent {
  text-indent: 30px;
}
</style>
